.filledMainButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    min-width: 185px;
    background-color: #F36A26;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    padding: 14px 20px;
    border-radius: 14px;
    cursor: pointer;
    border: none;
    box-sizing: border-box;
    transition: all .3s;
    text-decoration: none;
    height: 48px;
    max-width: fit-content;

    @media (max-width: 1340px) {
        min-width: 112px;
        width: 278px;
        padding: 10px;
        font-size: 11px;
        justify-content: space-between;
        gap: 0;
        height: 30px;
        border-radius: 10px;

        svg {
            height: 10px;
        }
	}

    &:hover {
        font-weight: 600;
    }
    
    @media (max-width: 980px) {
        height: 47px;
        min-width: 160px;
	}

    @media (max-width: 425px) {
        min-width: 144px;
        height: 42px;
	}
}

.mainButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    min-width: 185px;
    background-color: transparent;
    color: #18545C;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    padding: 14px 20px;
    border-radius: 14px;
    cursor: pointer;
    border: 2px solid #18545C;
    box-sizing: border-box;
    transition: all .3s;
    text-decoration: none;
    height: 48px;

    &:hover {
        font-weight: 600;
        background-color: #18545C;
        color: #ffffff;

        svg {
            path{
                fill: #ffffff;
            }
        }
    }

    svg {
        path{
            fill: #18545C;
        }
    }

    @media (max-width: 1340px) {
        min-width: 112px;
        max-width: fit-content;
        padding: 10px;
        font-size: 11px;
        justify-content: space-between;
        gap: 0;
        height: 30px;
        border-radius: 10px;

        svg {
            height: 10px;
        }
	}

    @media (max-width: 980px) {
        height: 47px;
        min-width: 160px;
	}

    @media (max-width: 425px) {
        min-width: 144px;
        height: 42px;
	}
}

.whiteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 185px;
    background-color: transparent;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    padding: 14px 0;
    border-radius: 14px;
    cursor: pointer;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    transition: all .3s;
    text-decoration: none;
    height: 48px;

    &:hover {
        font-weight: 600;
        background-color: #ffffff;
        color: #F36B24;
        
        svg {
            path{
                fill: #F36B24;
            }
        }
    }

    svg {
        path{
            fill: #ffffff;
        }
    }

    @media (max-width: 1340px) {
        min-width: 112px;
        max-width: fit-content;
        padding: 10px;
        font-size: 11px;
        justify-content: space-between;
        gap: 0;
        height: 30px;
        border-radius: 10px;

        svg {
            height: 10px;
        }
	}

    @media (max-width: 980px) {
        height: 47px;
        min-width: 160px;
	}

    @media (max-width: 425px) {
        min-width: 144px;
        height: 42px;
	}
}