.newFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 100px 270px;
    gap: 40px;
    font-family: 'Poppins', sans-serif;

    @media (max-width: 1700px) {
        padding: 100px 170px;
    }

    @media (max-width: 1440px) {
        padding: 40px 60px;  
    }

    @media (max-width: 1100px) {
        padding: 40px 80px;  
    }

    @media (max-width: 768px) {
        align-items: center;
    }

    @media (max-width: 550px) {
        padding: 40px 10% 20px 10%;
    }
}

.newFooterWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 900px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 650px) {
        gap: 50px;
    }
}

.leftSideFooter {
    display: flex;
    gap: 120px;

    @media (max-width: 1100px) {
        gap: 60px;
    }

    @media (max-width: 650px) {
       flex-direction: column-reverse;
    }
}

.footerImagesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 55px;

    @media (max-width: 650px) {
        display: none;
    }
}

.footerImagesWrapperMobile {
    display: none;
    @media (max-width: 650px) {
        display: flex;
        height: 202px ;
        flex-direction: column;
        justify-content: space-between;


    }
    @media (max-width: 550px) {
        height: 182px;
    }
    
    @media (max-width: 420px) {
        height: 172px;
    }
}

.footerWrapperMobile {
    display: flex;
    
    @media (max-width: 650px) {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 70px;
    }
}

.footerLogoImage {
    width: 100px;
    height: 120px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 1024px) {
        width: 60px;
        height: 70px;
    }
    @media (max-width: 768px) {
        width: 100px;
        height: 131px;
    }
    @media (max-width: 550px) {
        width: 70px;
        height: 86px;
    }
}

.footerSertImage {
    width: 128px;
    height: 70px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 1024px) {
        width: 70px;
        height: 40px;
    }
    @media (max-width: 768px) {
        width: 100px;
        height: 55px;
    }
    @media (max-width: 550px) {
        width: 70px;
        height: 43px;
    }
}

.footerLinksWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 10px;
    max-height: 275px;
    max-width: 500px;

    @media (max-width: 1100px) {
        row-gap: 10px;
        column-gap: 5px;
    }

    @media (max-width: 1024px) {
        max-width: 300px;
        column-gap: 15px;
    }

    @media (max-width: 768px) {
       max-width: 370px;
    }

    @media (max-width: 550px) {
        row-gap: 30px;
        column-gap: 20px;
     }
}

.newFooterLink {
    width: 46%;
    font-size: 18px;
    color: #146061;
    text-decoration: none;
    
    &:hover {
        font-weight: 600;
        transition: all 0.2s ease-in-out;
    }

    @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }

    @media (max-width: 650px) {
        font-size: 12px;
        line-height: 16px;
        // margin-left: 20px;;
    }
}

.rightSideFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 900px) {
        width: 100%;
        margin-top: 40px;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    @media (max-width: 650px) {
        flex: 1;
        max-width: fit-content;
        margin-top: 0px;
        align-items: start;
        max-width: fit-content;
    }

    @media (max-width: 550px) {
        gap:20px;
        
    }

}

.footerButtonWrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 45px;

    @media (max-width: 1024px) {
       column-gap: 12px;
    }

    @media (max-width: 790px) {
        justify-content: center;
    }

    @media (max-width: 650px) {
        flex-direction: column;
    }
}

.footerSocialMediaWrapper {
    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: 650px) {
        gap: 8px;
        
    }

}

.footerSocialMedia {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #146061;
    gap: 15px;

    .footerSocialMediaLink {
        cursor: pointer;
        margin: 0;
        padding: 0;
        height: 35px;
        width: 35px;

        svg {
            height: 100%;
            width: 100%;
            &:hover {
                rect {
                    fill: #F36A26;
                    transition: all 0.2s ease-in-out;
                }
            } 
        }


    }


    @media (max-width: 1380px) {
        font-size: 12px;
        .footerSocialMediaLink {
            width: 25px;
            height: 25px;
        }
    }

    @media (max-width: 900px) {
        .footerSocialMediaLink{
            width: 28px;
            height: 28px;
        }
    }

    @media (max-width: 650px) {
        gap: 10px;
        flex-direction: column;
        align-items: start;
        .footerSocialMediaLink {
            width: 20px;
            height: 20px;
        }
    }
}

.footerTermsWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 30px;
    font-size: 14px;
    color: #146061;
    row-gap: 10px;
    
    text-align: right;
    a, span{
       text-wrap: nowrap;
    }
    a {
        color: #146061;
        text-decoration: none;

        &:hover {
            font-weight: 600;
            transition: all 0.2s ease-in-out;
        }
    }

    @media (max-width: 1024px) {
        font-size: 11px;
    }

    @media (max-width: 650px) {
        display: none;
    }
    
}

.footerTermsWrapperMobile{
    display: none;
    flex-wrap: wrap;
    gap: 30px;
    color: #146061;
    font-size: 11px;
    margin-bottom: 60px;
    
    a {
        color: #146061;
        text-decoration: none;

        &:hover {
            font-weight: 600;
            transition: all 0.2s ease-in-out;
        }
    }

    a:last-child{
        width: 100%;
    }


    @media (max-width: 650px) {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        row-gap: 10px;

    }
}