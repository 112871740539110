.header {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: sticky;
	border-bottom: 1px solid #e1e4e8;
	font-family: 'Poppins', sans-serif;
	background-color: white;
	z-index: 30;
	top: 0;
	transition: 400ms;
}

.headerMotto {
	display: flex;
	background: #176061;
	height: 40px;

	@media (max-width: 1440px) {
		height: 22px;
	}

	@media (max-width: 768px) {
		height: 38px;
	}
	@media (max-width: 550px) {
		height: 25px;
	}

}

.mottoImage {
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-top: 6px;
	height: 26px;

	@media (max-width: 1440px) {
		margin-top: 4px;
		height: 15px;
	}

	@media (max-width: 768px) {
		height: 26px;
	}
	@media (max-width: 550px) {
		height: 18px;
	}
}

.headerMainButtonsWrapper {
	display: flex;
	row-gap: 5px;
	column-gap: 24px;

	@media (max-width: 1440px) {
        column-gap: 24px;
    }

	@media (max-width: 1340px) {
		column-gap: 10px;
		max-width: 250px;

		a { 
			width: 112px;
			max-width: 112px;
			height: 30px;
			border-radius: 6px;
			min-width: 112px;
		}
    }

	@media (max-width: 980px) {
		display: none;
	}
}

.headerMenu {
	display: none;

	@media (max-width: 980px) {
		display: flex;
		svg{
			
			width: 30px;
			height: 30px;
		}
	}
	@media (max-width: 767px) {
		svg{
			
			width: 17px;
			height: 17px;
		}
	}
}

.newHeaderContentWrapper {
	display: flex;
	gap: 40px;

	@media (max-width: 1440px) {
		justify-content: space-between;
	}

	@media (max-width: 980px) {
		gap: 20px;
	}
}

.newHeaderContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 124px;
	padding: 0 120px 0 95px;
	box-sizing: border-box;
	gap: 40px;
	width: 100%;
	transition: 200ms;

	.activeHeaderLink {
		color: #F36A26;
		font-weight: 700;
	}
	
	@media (max-width: 1700px) {
		padding: 0 20px 0 20px;
		gap: 20px;
	}

	@media (max-width: 1400px) {
		height: 66px;
	}

	@media (max-width: 1023px) {
        height: 93px;
		padding: 0 40px;

    }

	@media (max-width: 767px) {
		padding: 0 20px;
		justify-content: space-between;
		height: 51px;
	}
}

.newHeaderLink {
	display: flex;
	align-items: center;
	height: auto;
	color: #176061;
	font-size: 16px;
	text-decoration: none;
	text-transform: uppercase;
	transition: all .3s;

	&:hover {
		color: #F36A26;
		font-weight: 700;
	}

	@media (max-width: 1560px) {
		font-size: 11px;
	}

	@media (max-width: 1023px) {
		font-size: 10px;
	}
}

.newHeaderMenuWrapper {
	display: flex;
	gap: 10px;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 1440px) {
		gap: 7px;
	}
	@media (max-width: 980px) {
		display: none;
	}
}

.topLink {
	display: flex;
	gap: 40px;

	@media (max-width: 1440px) {
		gap: 24px
	}
}

.bottomLinks {
	display: flex;
	gap: 40px;
	align-items: flex-start;

	@media screen and (max-width:1400px) {
		gap: 24px;
	}
}

.headerTopLink {
	display: flex;
	align-items: center;
	height: auto;
	text-wrap: nowrap;
	color: #176061;
	font-weight: 700;
	font-size: 18px;
	text-decoration: none;
	text-transform: uppercase;
	transition: all .3s;

	&:hover {
		color: #F36A26;
		font-weight: 700;
	}

	@media (max-width: 1560px) {
		font-size: 16px;
	}

	@media (max-width: 1480px) {
		font-size: 14px;
	}

	@media (max-width: 1100px) {
		font-size: 11px;
	}
}

.logoLinkWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
}

.logoImage {
	width: 243px;
	height: 47px;
	flex-shrink: 0;
	background-position: center;
	background-repeat: no-repeat;

	@media (max-width: 1440px) {
		width: 149px;
		background-size: contain;
	}

	@media (max-width: 980px) {
		width: 227px;
	}

	@media (max-width: 767px) {
		width: 137px;
	}
}

.hireStaffLink {
	display: flex;
	align-items: center;
	gap: 30px;
	width: 100%;
	max-width: 266px;
	font-size: 16px;
	text-decoration: none;

	span {
		color: #156062;
	}

	&:hover {
		span {
			font-weight: 700;
		}
	}

	@media (max-width: 1440px) {
		gap: 15px;
		max-width: 205px;
	}
}

.hireStaffImage {
	width: 40px;
	height: 40px;
	flex-shrink: 0;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.hireStaffLinkWrapper {
	position: relative;
}

.hireStaffContainer {
	display: flex;
	flex-wrap: wrap;
	row-gap: 40px;
	column-gap: 90px;
	position: absolute;
	width: 980px;
	z-index: 1;
	top: 90px;
	background-color: #ffffff;
	border-radius: 0 0 14px 14px;
	padding: 40px 90px 70px;
	
	@media (max-width: 1440px) {
		padding: 20px;
		row-gap: 20px;
		width: 800px;
	}
}